define('ember-cli-deploy-ig-external/instance-initializers/enable-revision-previews-s3-index', ['exports'], function (exports) {
  // See: https://github.com/ember-cli-deploy/ember-cli-deploy-s3-index#previewing-revisions

  'use strict';

  exports.initialize = initialize;
  var windowLocation = {
    href: function href() {
      return window.location.href;
    }
  };

  exports.windowLocation = windowLocation;

  function initialize(appInstance) {
    var previewUrlMatch = windowLocation.href().match(/(index\.html:[\w\.-]*)((.?).*)/ // eslint-disable-line no-useless-escape
    );

    if (previewUrlMatch) {
      var container = appInstance.lookup ? appInstance : appInstance.container;
      var router = container.lookup('router:main');
      var trailingChar = previewUrlMatch[3];
      var initialURL = previewUrlMatch[2];

      if (trailingChar === '#') {
        initialURL = initialURL.replace('#', '');
      }

      router.initialURL = initialURL;
    }
  }

  exports['default'] = {
    name: 'enable-revision-previews-s3-index',
    initialize: initialize
  };
});